import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import FocusTrap from 'focus-trap-react';

const ModalContent = ({
  toggleModal,
  content,
  onKeyDown,
  modalRef,
  closeButtonRef,
  onClickOutside,
}) => {
  return ReactDOM.createPortal(
    <FocusTrap>
      <aside
        onKeyDown={onKeyDown}
        aria-modal="true"
        role="dialog"
        tabIndex="-1"
        className="modal-cover"
        onClick={onClickOutside}
      >
        <div className="modal-area" ref={modalRef}>
          <button
            onClick={toggleModal}
            className="_modal-close"
            aria-label="Close Modal"
            aria-labelledby="close-modal"
            ref={closeButtonRef}
          >
            <span id="close-modal" className="_hide-visual">
              Close
            </span>
            <svg className="_modal-close-icon" viewBox="0 0 40 40">
              <path strokeWidth="3" d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>
          <div className="modal-body">{content}</div>
        </div>
      </aside>
    </FocusTrap>,
    document.body
  );
};

ModalContent.propTypes = {
  content: PropTypes.element.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  modalRef: PropTypes.func.isRequired,
  closeButtonRef: PropTypes.func.isRequired,
  onClickOutside: PropTypes.func.isRequired,
};

export default ModalContent;
