import React from 'react';
import {
  InstantSearch,
  RefinementList,
  ClearRefinements,
  Panel,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';

import { searchClient } from './Search.utils';

const SearchFilters = ({
  filters,
  limit,
  onSearchStateChange,
  searchState,
  indexName,
}) => (
  <div className="search-filters-container">
    <div className="search-filters-container-inner">
      <h3 className="search-filter-header">Filter by:</h3>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
      >
        {filters.map(filter => (
          <React.Fragment key={filter.header}>
            <Panel
              header={filter.header}
              hidden={true}
              className="search-filter-group"
            >
              <RefinementList
                attribute={filter.attribute}
                showMore={true}
                limit={limit}
              />
            </Panel>
          </React.Fragment>
        ))}
        <ClearRefinements
          className="button-search button-primary"
          translations={{ reset: 'Clear all' }}
        />
      </InstantSearch>
    </div>
  </div>
);

SearchFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  limit: PropTypes.number.isRequired,
  onSearchStateChange: PropTypes.func.isRequired,
  searchState: PropTypes.object.isRequired,
  indexName: PropTypes.string.isRequired,
};

export default SearchFilters;
