import React, { useState } from 'react';
import Layout from '../components/Layout';
import qs from 'qs';
import Helmet from 'react-helmet';
import useSiteMetadata from '../components/SiteMetadata';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import SearchBox from '../components/search/SearchBox';

import SearchFilters from '../components/search/SearchFilters';
import {
  searchClient,
  VirtualRefinementList,
} from '../components/search/Search.utils';
import SearchResults from '../components/search/SearchResults';
import InfiniteHits from '../components/search/SearchInfiniteHits';
import Modal from '../components/modal/Modal';

import '../styles/reset.css';

const DEBOUNCE_TIME = 400;

const filters = [
  { header: 'Type', attribute: 'templateKey' },
  { header: 'Authors', attribute: 'authors' },
  { header: 'Speakers', attribute: 'speakers' },
  { header: 'Topic', attribute: 'tags' },
];

const indexName = 'postsAndEvents';

const SearchPage = ({ location, navigate }) => {
  const urlToSearchState = ({ search }) => qs.parse(search.slice(1));

  const { title } = useSiteMetadata();
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [debouncedSetState, setDebouncedSetState] = useState(null);

  const createURL = state => `?${qs.stringify(state)}`;

  const searchStateToUrl = searchState =>
    searchState ? `${location.pathname}${createURL(searchState)}` : '';

  const onSearchStateChange = updatedSearchState => {
    clearTimeout(debouncedSetState);

    setDebouncedSetState(
      setTimeout(() => {
        navigate(searchStateToUrl(updatedSearchState));
      }, DEBOUNCE_TIME)
    );

    setSearchState(updatedSearchState);
  };

  const Filters = () => (
    <SearchFilters
      filters={filters}
      limit={3}
      onSearchStateChange={onSearchStateChange}
      searchState={searchState}
      indexName={indexName}
    />
  );

  const modalSettings = {
    triggerText: 'Filters',
    content: Filters(),
  };

  return (
    <Layout pageType="PageWithFilters">
      <Helmet title={`Search | ${title}`} />
      <InstantSearch
        indexName="postsAndEvents"
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
      >
        <div className="main search-page">
          <div className="grid-container flex-start">
            <div className="col-xs-12 off-xs-0 col-sm-10 off-sm-1 col-md-6 off-md-0">
              <div className="search-input-text-container">
                <SearchBox />
              </div>
              <div className="search-mobile-filters-wrapper search-filters--mobile col-xs-12">
                {/* Virtual connector for unmounted fitlers in modal */}
                {filters.map(filter => (
                  <VirtualRefinementList
                    attribute={filter.attribute}
                    key={filter.attribute}
                  />
                ))}
                <Modal
                  triggerText={modalSettings.triggerText}
                  content={modalSettings.content}
                />
              </div>
              <h2 className="search-results-header col-xs-12 title-white">Search results:</h2>
            </div>
            <div className="blog-content-search-container grid-container flex-start">
              <div className="search-filters-column search-filters--desktop col-md-3">
                <Filters />
              </div>
              <div className="search-results-container col-xs-12 off-xs-0 col-sm-10 off-sm-1 col-md-9 off-md-0">
                <div className="search-result-column">
                  <Configure hitsPerPage={10} />
                  <SearchResults>
                    <InfiniteHits />
                  </SearchResults>
                </div>
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </Layout>
  );
};

export default SearchPage;
