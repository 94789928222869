import React from 'react';
import { connectStateResults } from 'react-instantsearch-dom';

const SearchResults = connectStateResults(
  ({ searchState, searchResults, children }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0;

    return (
      <div>
        <div hidden={!hasResults}>{children}</div>
        <p hidden={hasResults} className="no-results-message paragraph-white">
          There are no results for: {searchState.query}
        </p>
      </div>
    );
  }
);

export default SearchResults;
