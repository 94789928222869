import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalTrigger from './ModalTrigger';
import ModalContent from './ModalContent';

/*
  Reusable modal component
  Sample usage:

    const modalSettings = {
      triggerText: 'Filters', // text visible on button that triggers modal
      content: <div>My content!</div>,
  };

    <Modal
      triggerText={modalSettings.triggerText}
      content={modalSettings.content}
    />
*/

const Modal = ({ triggerText, content }) => {
  const [isShown, setIsShown] = useState(null);
  let modalRef = useRef(null);
  let closeButtonRef = useRef(null);
  let triggerButtonRef = useRef(null);

  const toggleModal = () => setIsShown(!isShown);

  const onKeyDown = event => {
    if (event.keyCode === 27) {
      toggleModal();
    }
  };

  const onClickOutside = event => {
    if (modalRef && modalRef.contains(event.target)) return;
    toggleModal();
  };

  const toggleScrollLock = () => {
    document.querySelector('html').classList.toggle('scroll-lock');
  };

  useEffect(() => {
    if (isShown) {
      closeButtonRef.focus();
      toggleScrollLock();
      return;
      // checking if === to not apply on mount, when it's equal null
    } else if (isShown === false) {
      triggerButtonRef.focus();
      toggleScrollLock();
    }
  }, [isShown]);

  return (
    <>
      <ModalTrigger
        toggleModal={toggleModal}
        triggerText={triggerText}
        triggerButtonRef={n => (triggerButtonRef = n)}
      />
      {isShown && (
        <ModalContent
          modalRef={n => (modalRef = n)}
          closeButtonRef={n => (closeButtonRef = n)}
          toggleModal={toggleModal}
          content={content}
          onKeyDown={onKeyDown}
          onClickOutside={onClickOutside}
        />
      )}
    </>
  );
};

Modal.propTypes = {
  content: PropTypes.element.isRequired,
  triggerText: PropTypes.string.isRequired,
};

export default Modal;
