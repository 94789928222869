import React from 'react';
import PropTypes from 'prop-types';

const ModalTrigger = ({ triggerText, toggleModal, triggerButtonRef }) => {
  return (
    <button
      onClick={toggleModal}
      className="modal-button button-primary"
      ref={triggerButtonRef}
    >
      {triggerText}
    </button>
  );
};

ModalTrigger.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  triggerButtonRef: PropTypes.func.isRequired,
  triggerText: PropTypes.string.isRequired,
};

export default ModalTrigger;
