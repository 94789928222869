import React, { useState }  from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { SvgIcon } from '../SvgIcon';

const SearchBox = ({ refine }) => {

  const [hasQuery, setHasQuery] = useState(false);

  const handleQueryChange = (event) => {
    refine(event.currentTarget.value);
    setHasQuery(!!event.currentTarget.value);
  }

  return (
    <div className="search-text-input">
      <form noValidate action="" role="search" className="ais-SearchBox-form">
        <input
          type="search"
          placeholder="Search"
          onChange={event => {
            handleQueryChange(event)
          }}
          className="ais-SearchBox-input"
        />
        <SvgIcon className="ais-SearchBox-searchIcon" useHref="icon-search" />
        <button 
          onClick={event => { handleQueryChange(event) }} 
          type="reset" 
          title="Clear the search query." 
          className="ais-SearchBox-reset" 
          hidden={!hasQuery}>
            
          <SvgIcon className="ais-SearchBox-resetIcon" useHref="icon-close" />
        </button>
      </form>
    </div>
  )
};

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;