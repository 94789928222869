import React from 'react';

import BlogPostCard from '../item-card/BlogPostCard';
import EventCard from '../item-card/EventCard';

const Hit = ({ hit }) => (
  <>
    {hit.templateKey === 'blog-post' ? (
      <BlogPostCard
        id={hit.objectID}
        publicationDate={hit.date}
        featuredImage={hit.featuredimage}
        cardColumns={12}
        {...hit}
      />
    ) : (
      <EventCard
        cardColumns={12}
        id={hit.objectID}
        eventDate={hit.date}
        featuredImage={hit.featuredimage}
        {...hit}
      />
    )}
  </>
);

export default Hit;
